.admin-training-questions .one-question .answer-wrap .answer-content:hover,
.admin-training-questions .one-question .question-content:hover {
    background-color: #dfdfdf
}
.admin-training-questions {
    position: relative;
    padding: 10px
}
.admin-training-questions .one-question {
    position: relative;
    margin-bottom: 15px
}
.admin-training-questions .one-question .question-content {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px
}
.admin-training-questions .one-question .answer-wrap {
    position: relative;
    padding-left: 30px;
    padding-top: 5px
}
.admin-training-questions .one-question .answer-wrap .answer-content {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 25px
}
.admin-training-questions .one-question .answer-wrap .answer-content .fa {
    width: 30px;
    text-align: center
}
.admin-training-questions .quick-btn {
    float: right;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: background-color .3s
}
.admin-training-questions .quick-btn .fa {
    width: auto!important;
    height: auto!important
}
.admin-training-questions .quick-btn:hover {
    background-color: #d2d2d2
}
.inner-loader,
.page-loader {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, .4)
}
.inner-loader>span,
.page-loader>span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block
}
.page-loader {
    position: fixed;
    z-index: 99999999;
    background: rgba(100, 100, 100, .4)
}
.navbar-fixed-top {
    z-index: 1000
}
.project-select-ui .btn-default {
    background-color: #fff
}
.btn-grey-scale {
    color: #fff;
    background-color: #CCC;
    border-color: #CCC
}
.img-task {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    vertical-align: top;
    border-radius: 50%;
    overflow: hidden
}
.img-task img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.task-info {
    position: relative;
    display: inline-block;
    padding-top: 10px
}
.db-4m-status,
.task-info i {
    padding: 3px 10px;
    color: #fff;
    border-radius: 3px
}
.db-4m-status.db-4m-done {
    background-color: #206C01
}
.db-4m-status.db-4m-0 {
    background-color: #DF2300
}
.db-4m-status.db-4m-not-yet {
    background-color: #f28e20
}
.table-control-task .btn-control {
    display: inline-block;
    border: 1px solid #e4eaec;
    color: #000;
    background-color: #fff;
    text-decoration: none;
    width: 24px;
    height: 24px;
    position: relative;
    vertical-align: top
}
.table-control-task .btn-control i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.pointer {
    cursor: pointer
}
.table-risk tr td:first-child {
    width: 16%
}
.table-risk tr td:not(:first-child) {
    width: 14%
}
.table-risk tr td.active-chosen {
    animation-name: riskactive;
    animation-duration: .5s;
    animation-iteration-count: infinite;
    animation-direction: reverse
}
@keyframes riskactive {
    0% {
        opacity: .2
    }
    25% {
        opacity: .4
    }
    50% {
        opacity: .6
    }
    75% {
        opacity: .8
    }
    100% {
        opacity: 1
    }
}
.modal-backdrop {
    z-index: 1020
}
.email-modal {
    height: 547px
}
.master-zIndex {
    z-index: 9999!important
}
.ui-select-custom .btn-default {
    background-color: #fff
}
.model-edit-ra {
    overflow: auto!important
}
.model-edit-ra .modal-dialog {
    right: 0;
    top: 0;
    left: auto;
    bottom: 0;
    position: absolute;
    margin: 0;
    width: 620px;
    transform: translate(0, 0)
}
.model-edit-ra .modal-dialog .modal-content {
    border-radius: 0;
    margin: 0
}
.model-edit-ra .modal-dialog .modal-content .modal-header {
    background: #f28e20;
    color: #fff
}
.hau-qua label,
.tan-suat label {
    min-width: 100px!important
}
.training-info>div {
    padding: 0 15px;
    line-height: 38px;
    font-size: 14px
}
.training-info>div.header {
    color: #fff;
    background-color: #f28e20!important;
    text-transform: uppercase
}
.training-info>div.training-info-item {
    color: #000;
    background-color: #fff;
    cursor: pointer
}
.training-info>div.training-info-item.active,
.training-info>div.training-info-item:hover {
    color: #fff;
    background-color: #f28e20!important
}
.table-training h6 {
    font-weight: 400;
    color: #000;
    font-size: 14px
}
.table-training::after {
    top: 0
}
.create-report-modal .img-attach {
    position: relative;
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden
}
.create-report-modal .img-attach img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%
}
.daily-table .see-details-1::after,
.daily-table .see-details::after {
    content: "";
    transform: rotate(-45deg);
    left: -10px
}
.create-report-modal .img-attach span.icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    font-size: 12px;
    cursor: pointer
}
.create-report-modal .img-attach span.icon-close:hover {
    font-size: 14px;
    top: 4px;
    right: 4px
}
.ui-select-container input.ui-select-search {
    width: 100%!important
}
.daily-table .see-details {
    text-decoration: none!important;
    background: #f28e20;
    height: auto;
    width: auto;
    display: block;
    padding: 4px 23px 4px 3px;
    margin-bottom: 10px;
    color: #FFF;
    position: relative;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}
.daily-table .see-details::after {
    position: absolute;
    top: 4px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top: 11px solid #f28e20;
    border-left: 11px solid #f28e20;
    border-top-left-radius: 4px
}
.daily-table .see-details-1 {
    text-decoration: none!important;
    background: #0098f9;
    height: auto;
    width: auto;
    display: block;
    padding: 4px 23px 4px 3px;
    margin-bottom: 0;
    color: #FFF;
    position: relative;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}
/*.daily-table .see-details-1:hover {
    color: #00f;
    text-decoration: underline
}*/
.daily-table .see-details-1::after {
    position: absolute;
    top: 4px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top: 11px solid #0098f9;
    border-left: 11px solid #0098f9;
    border-top-left-radius: 4px
}
.input-wapp.high label {
    background-color: red!important
}
.input-wapp.high label::after {
    border-top-color: red!important;
    border-left-color: red!important
}
.input-wapp.low label {
    background-color: #00f!important
}
.input-wapp.low label::after {
    border-top-color: #00f!important;
    border-left-color: #00f!important
}
.cow-modal .modal-dialog {
    position: absolute;
    right: 0;
    bottom: 0
}
.cow-modal .modal-dialog .modal-body {
    padding: 0;
    max-height: 450px;
    overflow-y: scroll
}
.cow-modal .modal-dialog .modal-body form {
    padding: 5px
}
.ra-project-scroll {
    max-height: 580px!important;
    max-width: 300px;
    overflow: hidden;
    float: left
}
.ra-project-scroll .nav-tabs {
    float: none
}
.sequel-frequency-checkbox {
    display: table;
    padding: 10px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1;
    cursor: pointer
}
.sequel-frequency-checkbox.sequel-frequency-1 {
    background-color: #00bcd4!important
}
.sequel-frequency-checkbox.sequel-frequency-2 {
    background-color: #4caf50!important
}
.sequel-frequency-checkbox.sequel-frequency-3 {
    background-color: #ff9800!important
}
.sequel-frequency-checkbox.sequel-frequency-4 {
    background-color: #f25a20!important
}
.sequel-frequency-checkbox.sequel-frequency-5 {
    background-color: #f44336!important
}
.sequel-frequency-checkbox .labelauty-checked-image,
.sequel-frequency-checkbox .labelauty-unchecked-image {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    background-repeat: no-repeat;
    background-position: left center;
    transition: background-image .5s linear
}
.sequel-frequency-checkbox .labelauty-checked,
.sequel-frequency-checkbox .labelauty-unchecked {
    display: inline-block;
    line-height: 16px;
    vertical-align: bottom;
    margin-left: 7px
}
.site-menubar-body {
    height: calc(100% - 97px)
}
.aside-max-height {
    max-height: 100%;
    height: 100%;
    overflow: hidden
}
.line-header-left {
    width: 10%;
    background: #000;
    transform: skew(50deg);
    margin-left: 30%;
    float: left;
    height: 40px
}
.line-header-right {
    width: 70%;
    background: #f79623;
    transform: skew(50deg);
    float: right;
    margin-right: -10%;
    height: 40px
}
.title {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase
}
.line-footer-left {
    height: 19px;
    width: 60%;
    background: #000;
    transform: skew(50deg);
    margin-left: -5%;
    border-bottom: 1px solid #000
}
.line-footer-right {
    height: 19px;
    width: 50%;
    background: #f79623;
    transform: skew(50deg);
    float: right;
    margin-right: -5%;
    border-bottom: 1px solid #f79623
}
.line-header-right .text {
    color: #fff;
    transform: skew(-50deg);
    padding: 10px 5px 8px 15px;
    width: 238px;
    font-size: 14px;
    text-align: center
}
.card-container {
    page-break-inside: avoid;
    overflow: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
    background-color: #fff;
    position: relative;
    font-family: "DejaVu Sans", Arial, sans-serif;
    color: #333;
    font-size: 13px
}
.list-info tbody tr td {
    padding-bottom: 1px
}
.employee-card .modal-dialog {
    width: 460px
}
.scrollbar-dynamic>.scroll-element.scroll-y.scroll-draggable .scroll-element_outer,
.scrollbar-dynamic>.scroll-element.scroll-y:hover .scroll-element_outer {
    width: 8px
}
.incident-table-select2 ul.ui-select-choices {
    right: 0;
    left: auto;
    width: 300px
}
.incident-table-select2 input.ui-select-search {
    width: 200px!important
}
.incident-table-select2 div.ui-select-match {
    width: 200px!important;
    overflow: hidden
}
.incident-table-select2 div.ui-select-match span.ui-select-match-text {
    width: 100%;
    overflow: hidden
}
.container-video-stream {
    max-width: 100%;
    padding: 5px;
    background-color: #efefef
}
.container-video-stream .app {
    width: 100%;
    position: relative
}
.container-video-stream .app #start-camera {
    display: none;
    border-radius: 3px;
    max-width: 400px;
    color: #fff;
    background-color: #448AFF;
    text-decoration: none;
    padding: 15px;
    opacity: .8;
    margin: 50px auto;
    text-align: center
}
.container-video-stream .app video#camera-stream {
    display: none;
    width: 100%
}
.container-video-stream .app img#snap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: none
}
.container-video-stream .app #error-message {
    width: 100%;
    background-color: #ccc;
    color: #9b9b9b;
    font-size: 28px;
    padding: 200px 100px;
    text-align: center;
    display: none
}
.container-video-stream .app .controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    align-items: flex-end;
    justify-content: space-between;
    padding: 5px;
    display: none
}
.container-video-stream .app .controls a {
    position: relative;
    border-radius: 50%;
    color: #fff;
    background-color: #111;
    text-decoration: none;
    padding: 15px;
    line-height: 0;
    opacity: .7;
    outline: 0;
    width: 50px;
    height: 50px;
    -webkit-tap-highlight-color: transparent
}
.container-video-stream .app .controls a:hover {
    opacity: 1
}
.container-video-stream .app .controls a.disabled {
    background-color: #555;
    opacity: .5;
    cursor: default;
    pointer-events: none
}
.container-video-stream .app .controls a.disabled:hover {
    opacity: .5
}
.container-video-stream .app .controls a span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px
}
.container-video-stream .app .controls #take-photo span {
    font-size: 20px
}
.container-video-stream .app canvas {
    display: none
}
.container-video-stream .app #error-message.visible,
.container-video-stream .app img#snap.visible,
.container-video-stream .app video#camera-stream.visible {
    display: block
}
.container-video-stream .app .controls.visible {
    display: flex
}
@media (max-width: 1000px) {
    .container-video-stream .app #start-camera.visible {
        display: block
    }
    .container-video-stream .app .controls a i {
        font-size: 16px
    }
    .container-video-stream .app .controls #take-photo i {
        font-size: 24px
    }
}
@media (max-width: 600px) {
    .container-video-stream .app #error-message {
        padding: 80px 50px;
        font-size: 18px
    }
    .container-video-stream .app .controls a i {
        font-size: 12px
    }
    .container-video-stream .app .controls #take-photo i {
        font-size: 18px
    }
}

.label-5 {
    background-color: #ffff00 !important;
}

.label-4 {
    background-color: #ff9900 !important;
}

.label-3 {
    background-color: #cc0000 !important;
}